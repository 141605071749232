import { createSlice } from "@reduxjs/toolkit"

const applicationStateSlice = createSlice({
  name: "applicationState",
  initialState: {
    navigationActive: false,
  },
  reducers: {
    setNavigationActive(state, action) {
      state.navigationActive = action.payload
    },
  },
})

export const { setNavigationActive } = applicationStateSlice.actions

export default applicationStateSlice.reducer
